// normalize CSS across browsers
import "./src/normalize.css"
// custom CSS styles
import "./src/style.css"

// Highlighting for code blocks
import "prismjs/themes/prism.css"

// HubSpot Form https://github.com/aaronhayes/react-use-hubspot-form
import React from 'react';
import { HubspotProvider } from '@aaronhayes/react-use-hubspot-form';

export const wrapRootElement = ({ element }) => {
  return (
    <HubspotProvider>
      {element}
    </HubspotProvider>
  );
};

// HubSpot tracking code
export const onRouteUpdate = ({ location, prevLocation }) => {
  // for SPA page traking
  if (window._hsq && prevLocation !== null) {
    // setTimeoutを使用して非同期にHubSpotのトラッキングを呼び出す
    // これにより、React Helmetによるdocument.titleの更新を待つことができる
    setTimeout(() => {
      window._hsq.push(["setPath", location.pathname]);
      window._hsq.push(["trackPageView"]);
    }, 100); // setTimeoutの遅延時間・ミリ秒
  }
};
